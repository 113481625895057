import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import axios from "axios";
import Table from "../components/tableComponent";
import NewPartModal from "../components/NewPartModal"; // Import NewPartModal

const compatibilityOptions = [
  "GIA-22",
  "SUP-22",
  "RIE-22",
  "GIA-23",
  "WHI-23",
  "GIA-24",
];

const ServiceParts = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterInput, setFilterInput] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state
  const [uniqueSuppliers, setUniqueSuppliers] = useState([]);
  const [uniqueGroupCodes, setUniqueGroupCodes] = useState([]);
  const [uniqueComponentCodes, setUniqueComponentCodes] = useState({});

  useEffect(() => {
    const fetchServiceParts = async () => {
      try {
        const response = await axios.get("/api/service-parts");
        setData(response.data || []);
        extractUniqueValues(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching service parts:", error);
        setLoading(false);
      }
    };

    fetchServiceParts();
  }, []);

  const extractUniqueValues = (servicePartsData) => {
    const suppliers = new Set();
    const groupCodes = new Set();
    const componentCodes = {};

    servicePartsData.forEach((part) => {
      suppliers.add(part.supplier);
      groupCodes.add(part.groupCode);

      if (part.groupCode) {
        if (!componentCodes[part.groupCode])
          componentCodes[part.groupCode] = new Set();
        componentCodes[part.groupCode].add(part.componentCode);
      }
    });

    setUniqueSuppliers(Array.from(suppliers));
    setUniqueGroupCodes(Array.from(groupCodes));
    setUniqueComponentCodes(
      Object.fromEntries(
        Object.entries(componentCodes).map(([k, v]) => [k, Array.from(v)])
      )
    );
  };

  const toggleModalVisibility = () => setIsModalOpen(!isModalOpen);

  const handleNewPartSubmit = (newPart) => {
    setData([...data, newPart]);
    toggleModalVisibility();
  };

  const handleFilterChange = (e) => {
    setFilterInput(e.target.value);
    if (filterCategory) {
      setFilter(filterCategory, e.target.value);
    }
  };

  const handleUpdateModelCompatibility = useCallback(
    async (id, selectedModels) => {
      try {
        await axios.put(`/api/service-parts/${id}/model-compatibility`, {
          modelCompatibility: selectedModels,
        });
      } catch (error) {
        console.error("Error updating model compatibility:", error);
      }
    },
    []
  );

  const handleDeletePart = useCallback(
    async (id) => {
      const isConfirmed = window.confirm(
        "Are you sure you want to delete this part?"
      );
      if (!isConfirmed) return;

      try {
        const response = await axios.delete(`/api/service-parts/${id}`);
        if (response.status === 200) {
          setData(data.filter((item) => item._id !== id));
        }
      } catch (error) {
        console.error("Error deleting service part:", error);
      }
    },
    [data]
  );

  const columns = useMemo(
    () => [
      { Header: "Product Description", accessor: "productDescription" },
      { Header: "Product No", accessor: "productNo" },
      { Header: "Supplier", accessor: "supplier" },
      { Header: "Purchase Cost (Ex VAT)", accessor: "purchaseCostExVat" },
      { Header: "Sales Price (Inc VAT)", accessor: "salesPriceIncVat" },
      { Header: "MRSP (Inc VAT)", accessor: "MRSPIncVat" },
      { Header: "Group Code", accessor: "groupCode" },
      { Header: "Component Code", accessor: "componentCode" },
      { Header: "Part Code", accessor: "partCode" },
      {
        Header: "Model Compatibility",
        accessor: "modelCompatibility",
        Cell: ({ row }) => {
          const [isDropdownOpen, setIsDropdownOpen] = useState(false);
          const [localSelectedModels, setLocalSelectedModels] = useState(
            row.original.modelCompatibility || []
          );
          const [isUpdating, setIsUpdating] = useState(false);

          const handleCheckboxChange = (option) => {
            const updatedModels = localSelectedModels.includes(option)
              ? localSelectedModels.filter((model) => model !== option)
              : [...localSelectedModels, option];
            setLocalSelectedModels(updatedModels);
          };

          const handleUpdateClick = async () => {
            setIsUpdating(true);
            await handleUpdateModelCompatibility(
              row.original._id,
              localSelectedModels
            );
            setData((prevData) =>
              prevData.map((item) =>
                item._id === row.original._id
                  ? { ...item, modelCompatibility: localSelectedModels }
                  : item
              )
            );
            setIsUpdating(false);
            setIsDropdownOpen(false);
          };

          return (
            <div className="relative">
              <button
                className="bg-joule text-white px-3 py-1 rounded-lg"
                onClick={() => setIsDropdownOpen((prev) => !prev)}
              >
                Edit Compatibility
              </button>
              {isDropdownOpen && (
                <div className="absolute mt-2 bg-white border border-gray-300 rounded-lg shadow-lg p-6 w-60 z-10 text-xl">
                  {compatibilityOptions.map((option) => (
                    <label
                      key={option}
                      className="flex items-center py-2 text-lg space-x-3"
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox accent-joule rounded-full h-6 w-6 cursor-pointer"
                        checked={localSelectedModels.includes(option)}
                        onChange={() => handleCheckboxChange(option)}
                      />
                      <span>{option}</span>
                    </label>
                  ))}
                  <button
                    onClick={handleUpdateClick}
                    disabled={isUpdating}
                    className={`mt-4 py-2 px-4 rounded-lg w-full ${
                      isUpdating
                        ? "bg-gray-400 cursor-wait text-white"
                        : "bg-joule text-white cursor-pointer hover:bg-joule-dark"
                    }`}
                  >
                    {isUpdating ? "Updating..." : "Update"}
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => (
          <button
            onClick={() => handleDeletePart(row.original._id)}
            className="text-red-500 hover:text-red-700"
          >
            ❌
          </button>
        ),
      },
    ],
    [handleDeletePart, handleUpdateModelCompatibility]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    setFilter,
    rows,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex-grow p-4">
      <h2 className="text-2xl font-semibold mb-4">Service Parts</h2>

      {/* Filters and Add New Part Button Row */}
      <div className="flex justify-between items-center mb-8">
        <div className="flex space-x-2">
          {/* Filter Category Selection */}
          <select
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
            className="border p-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-400"
          >
            <option value="">Select Filter Category...</option>
            {columns.map((column) => (
              <option value={column.accessor} key={column.accessor}>
                {column.Header}
              </option>
            ))}
          </select>

          {/* Text input for entering filter values */}
          <input
            value={filterInput}
            onChange={handleFilterChange}
            placeholder="Enter filter value..."
            className="border p-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>

        {/* Add New Part Button */}
        <button
          onClick={toggleModalVisibility}
          className="py-2 px-4 bg-joule text-white rounded-lg hover:bg-joule-dark"
        >
          Add New Part
        </button>
      </div>

      <Table
        columns={columns}
        data={page}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalFilteredRows={rows.length}
      />

      {/* New Part Modal */}
      {isModalOpen && (
        <NewPartModal
          isOpen={isModalOpen}
          onRequestClose={toggleModalVisibility}
          onSubmit={handleNewPartSubmit}
          suppliers={uniqueSuppliers}
          groupCodes={uniqueGroupCodes}
          componentCodes={uniqueComponentCodes}
          compatibilityOptions={compatibilityOptions}
        />
      )}
    </div>
  );
};

export default ServiceParts;
