// serviceRegistration.jsx

import React, { useState, useEffect } from "react";
import "../calendar.css";
import ReactModal from "react-modal";
import axios from "axios";

ReactModal.setAppElement("#root");

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0]; // Returns date in YYYY-MM-DD format
};

const modalStyles = {
  content: {
    maxWidth: "1000px",
    width: "90%",
    margin: "auto",
    padding: "30px",
    borderRadius: "12px",
    border: "4px solid #374151",
    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.6)",
    overflow: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    zIndex: 1000,
  },
};

const ServiceRegistrationForm = ({
  formData,
  handleChange,
  handleSubmit,
  toggleModalVisibility,
  errorMessage,
  isLoading,
  doNotSaveService,
  setDoNotSaveService,
  groupedParts,
  selectedParts,
  setSelectedParts,
  selectedAccessories,
  setSelectedAccessories,
  source,
}) => {
  const [expandedGroups, setExpandedGroups] = useState({});

  const handlePartSelection = (
    productNo,
    productDescription,
    purchaseCostExVat,
    MRSPIncVat,
    salesPriceIncVat,
    quantity
  ) => {
    setSelectedParts((prevParts) => {
      const partIndex = prevParts.findIndex(
        (part) => part.productNo === productNo
      );
      if (partIndex > -1) {
        // If part exists and quantity is 0, remove it from the list
        if (quantity === 0) {
          return prevParts.filter((part) => part.productNo !== productNo);
        } else {
          // Update the quantity
          const updatedParts = [...prevParts];
          updatedParts[partIndex].quantity = quantity;
          return updatedParts;
        }
      } else {
        // Add the part if it's new and quantity is non-zero
        return quantity > 0
          ? [
              ...prevParts,
              {
                productNo,
                productDescription,
                purchaseCostExVat,
                MRSPIncVat,
                salesPriceIncVat,
                quantity,
              },
            ]
          : prevParts;
      }
    });
  };

  const handleAccessorySelection = (accessoryName, quantity) => {
    setSelectedAccessories((prevAccessories) => {
      const accessoryIndex = prevAccessories.findIndex(
        (accessory) => accessory.name === accessoryName
      );
      if (accessoryIndex > -1) {
        if (quantity === 0) {
          return prevAccessories.filter(
            (accessory) => accessory.name !== accessoryName
          );
        } else {
          const updatedAccessories = [...prevAccessories];
          updatedAccessories[accessoryIndex].quantity = quantity;
          return updatedAccessories;
        }
      } else {
        return quantity > 0
          ? [...prevAccessories, { name: accessoryName, quantity }]
          : prevAccessories;
      }
    });
  };

  const toggleGroup = (groupCode) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [groupCode]: !prev[groupCode],
    }));
  };

  return (
    <div>
      <div className="text-center mb-6">
        <h2 className="text-gray-900 text-3xl font-semibold">
          Service Registration
        </h2>
        <p className="text-gray-600 text-lg font-medium mt-2">
          Service Type:{" "}
          <span className="text-gray-800 font-semibold">
            {formData.serviceType}
          </span>
        </p>
      </div>
      {/* Divider */}
      <hr className="my-6 border-t border-gray-300" />
      <form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
        {/* Service Data Section */}
        <div className="space-y-4">
          <div>
            <label
              className="block text-gray-700 text-md font-semibold mb-2"
              htmlFor="serviceDate"
            >
              Service Date
            </label>
            <input
              className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:border-joule focus:ring-2 focus:ring-joule"
              id="serviceDate"
              type="date"
              name="serviceDate"
              value={formData.serviceDate}
              onChange={handleChange}
            />
          </div>

          <div>
            <label
              className="block text-gray-700 text-md font-semibold mb-2"
              htmlFor="serialNumber"
            >
              Serial number
            </label>
            <input
              className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-700 bg-gray-100 focus:outline-none"
              id="serialNumber"
              type="text"
              name="serialNumber"
              value={formData.serialNumber}
              readOnly
            />
          </div>

          <div>
            <label
              className="block text-gray-700 text-md font-semibold mb-2"
              htmlFor="km"
            >
              Kilometer/ODO
            </label>
            <input
              className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:border-joule focus:ring-2 focus:ring-joule"
              id="km"
              type="number"
              name="km"
              value={formData.km}
              onChange={handleChange}
            />
          </div>

          <div>
            <label
              className="block text-gray-700 text-md font-semibold mb-2"
              htmlFor="serviceCategory"
            >
              Service Category
            </label>
            <select
              className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:border-joule focus:ring-2 focus:ring-joule"
              id="serviceCategory"
              name="serviceCategory"
              value={formData.serviceCategory}
              onChange={handleChange}
            >
              <option value="">Select Category</option>
              <option value="refresh">Refresh</option>
              <option value="standard service">Standard service</option>
              <option value="full service">Full service</option>
              <option value="tire change">Tire change</option>
              <option value="corrective service">Corrective service</option>
            </select>
          </div>

          <div>
            <label
              className="block text-gray-700 text-md font-semibold mb-2"
              htmlFor="note"
            >
              Note
            </label>
            <textarea
              className="w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:border-joule focus:ring-2 focus:ring-joule"
              id="note"
              name="note"
              value={formData.note}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Parts Used Section */}
        <div className="my-8">
          <hr className="border-t border-gray-300" />
          <h3 className="text-2xl font-bold text-gray-800 my-6 flex items-center">
            <span className="mr-2">🛠️</span> Parts Used in Service
          </h3>
          {Object.entries(groupedParts).map(([groupCode, parts]) => (
            <div key={groupCode} className="mb-4">
              <button
                type="button"
                onClick={() => toggleGroup(groupCode)}
                className="text-lg font-semibold text-gray-800"
              >
                {groupCode}
              </button>
              {expandedGroups[groupCode] && (
                <div className="ml-4 mt-4 mb-4">
                  {parts.map((part) => (
                    <div
                      key={part.productNo}
                      className="flex items-center mb-4"
                    >
                      <input
                        type="checkbox"
                        id={part.productNo}
                        className="form-checkbox h-5 w-5 accent-joule border-gray-300 rounded"
                        checked={selectedParts.some(
                          (selected) => selected.productNo === part.productNo
                        )}
                        onChange={(e) =>
                          handlePartSelection(
                            part.productNo,
                            part.productDescription,
                            part.purchaseCostExVat,
                            part.MRSPIncVat,
                            part.salesPriceIncVat,
                            e.target.checked ? 1 : 0
                          )
                        }
                      />
                      <label
                        htmlFor={part.productNo}
                        className="ml-2 text-gray-700 font-medium"
                      >
                        {part.productDescription}
                      </label>
                      <input
                        type="number"
                        min="1"
                        className="ml-2 w-16 border border-gray-300 rounded-md px-2"
                        placeholder="Qty"
                        value={
                          selectedParts.find(
                            (selected) => selected.productNo === part.productNo
                          )?.quantity || ""
                        }
                        onChange={(e) =>
                          handlePartSelection(
                            part.productNo,
                            part.productDescription,
                            part.purchaseCostExVat,
                            part.MRSPIncVat,
                            part.salesPriceIncVat,
                            parseInt(e.target.value, 10) || 0
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Accessories Section */}
        {source === "orders" && (
          <div className="my-8">
            <hr className="border-t border-gray-300" />
            <h3 className="text-2xl font-bold text-gray-800 my-6 flex items-center">
              <span className="mr-2">👜</span> Accessories sending out to
              customer
            </h3>
            <div className="ml-4 mt-2">
              {[
                "charger",
                "adapter",
                "snakeLock",
                "foldingLock",
                "lockKey",
                "batteryKey",
                "reTyreSkins",
              ].map((accessoryName) => (
                <div key={accessoryName} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id={accessoryName}
                    className="form-checkbox h-5 w-5 accent-joule border-gray-300 rounded"
                    checked={selectedAccessories.some(
                      (selected) => selected.name === accessoryName
                    )}
                    onChange={(e) =>
                      handleAccessorySelection(
                        accessoryName,
                        e.target.checked ? 1 : 0
                      )
                    }
                  />
                  <label
                    htmlFor={accessoryName}
                    className="ml-2 text-gray-700 font-medium w-32"
                  >
                    {accessoryName.charAt(0).toUpperCase() +
                      accessoryName.slice(1)}
                  </label>
                  <div className="flex items-center ml-4">
                    <button
                      type="button"
                      onClick={() =>
                        handleAccessorySelection(
                          accessoryName,
                          Math.max(
                            (selectedAccessories.find(
                              (selected) => selected.name === accessoryName
                            )?.quantity || 1) - 1,
                            0
                          )
                        )
                      }
                      className="h-8 w-8 bg-joule text-white font-bold rounded-l-md"
                    >
                      -
                    </button>
                    <input
                      type="text"
                      readOnly
                      className="h-8 w-8 border border-gray-300 text-center"
                      value={
                        selectedAccessories.find(
                          (selected) => selected.name === accessoryName
                        )?.quantity || 0
                      }
                    />
                    <button
                      type="button"
                      onClick={() =>
                        handleAccessorySelection(
                          accessoryName,
                          (selectedAccessories.find(
                            (selected) => selected.name === accessoryName
                          )?.quantity || 0) + 1
                        )
                      }
                      className="h-8 w-8 bg-joule text-white font-bold rounded-r-md"
                    >
                      +
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Action buttons and other elements */}
        <hr className="border-t border-gray-300" />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8">
          <button
            type="submit"
            className={`w-full py-2 px-6 font-semibold rounded-lg shadow-md focus:outline-none ${
              isLoading
                ? "bg-gray-500 text-white"
                : "bg-joule text-white hover:bg-gray-900"
            }`}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                Registering...
              </>
            ) : (
              "Register Service"
            )}
          </button>
          <button
            type="button"
            className="w-full py-2 px-6 font-semibold rounded-lg shadow-md bg-red-500 text-white hover:bg-red-700 focus:outline-none"
            onClick={toggleModalVisibility}
            disabled={isLoading}
          >
            Cancel
          </button>
        </div>

        {/* Checkbox */}
        <div className="flex items-center" style={{ marginTop: "40px" }}>
          <input
            type="checkbox"
            checked={doNotSaveService}
            onChange={(e) => setDoNotSaveService(e.target.checked)}
            className="form-checkbox h-5 w-5 accent-joule border-gray-300 rounded"
          />
          <span className="ml-2 text-gray-700">Do not save service</span>
        </div>

        {/* Error Message */}
        {errorMessage && (
          <div className="mt-4 text-red-600 text-center">{errorMessage}</div>
        )}
      </form>
    </div>
  );
};

// ServiceCell component
const ServiceCell = ({
  isOpen,
  data,
  source,
  toggleModalVisibility,
  markAsRepaired,
  reloadData,
}) => {
  // Determine the serviceType based on the source and type
  let serviceType = "";
  if (source === "returns") {
    serviceType = "Return Service";
  } else if (source === "orders") {
    serviceType = "Prepare Service";
  } else if (source === "bookings") {
    serviceType = data.type === "Service" ? "Booked Service" : "Booked Repair";
  } else if (source === "itemPage") {
    serviceType = "From stock";
  }

  const [formData, setFormData] = useState({
    serviceDate: getCurrentDate(),
    serialNumber: data.serialNumber || "",
    km: data.km || "",
    note: data.note || "",
    serviceType,
    serviceCategory: "",
  });

  const [groupedParts, setGroupedParts] = useState({});
  const [selectedParts, setSelectedParts] = useState([]); // Track selected parts
  const [selectedAccessories, setSelectedAccessories] = useState([]);
  const [doNotSaveService, setDoNotSaveService] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchServiceParts = async () => {
      try {
        // Step 1: Fetch SKU by serial number
        const { data: inventoryData } = await axios.get(
          `/api/inventory/${formData.serialNumber}`
        );
        const modelCode = inventoryData.model.SKU.slice(0, 6);

        // Step 2: Fetch service parts by model compatibility
        const { data: partsData } = await axios.get(
          `/api/service-parts/${modelCode}`
        );

        // Step 3: Group service parts by `groupCode`
        const grouped = partsData.reduce((acc, part) => {
          const { groupCode } = part;
          if (!acc[groupCode]) acc[groupCode] = [];
          acc[groupCode].push(part);
          return acc;
        }, {});
        setGroupedParts(grouped);
      } catch (error) {
        console.error("Error fetching compatible service parts:", error);
      }
    };

    fetchServiceParts();
  }, [formData.serialNumber]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset error message on new submission

    // Conditional validation for mandatory fields
    if (!doNotSaveService && (!formData.km || !formData.serviceCategory)) {
      setErrorMessage(
        "Please fill out both the KM and Service Category fields."
      );
      return;
    }

    setIsLoading(true); // Start loading

    try {
      let operationalStatusPayload;
      if (source === "returns")
        operationalStatusPayload = { operationalStatus: "in stock" };
      else if (source === "orders" || source === "bookings")
        operationalStatusPayload = { operationalStatus: "ready for customer" };

      if (operationalStatusPayload) {
        await axios.put(
          `/api/inventory/${formData.serialNumber}/operationalStatus`,
          operationalStatusPayload
        );
      }

      const servicePayload = {
        ...formData,
        partsUsed: selectedParts, // Include selected parts in the service payload
      };

      if (!doNotSaveService) {
        await axios.post("/api/services", servicePayload);
        await axios.put(`/api/inventory/${formData.serialNumber}`, {
          lastService: formData.serviceDate,
          lastKm: parseInt(formData.km, 10),
        });
      }

      // Update accessories if source is "orders"
      if (source === "orders") {
        const accessoriesPayload = selectedAccessories.reduce((acc, item) => {
          acc[item.name] = item.quantity;
          return acc;
        }, {});
        await axios.put(
          `/api/inventory/${formData.serialNumber}/accessories`,
          accessoriesPayload
        );
      }

      if (source === "bookings") {
        const smsData = { number: data.number, bookingId: data._id };
        try {
          await axios.post("/api/send-service-ready-sms", smsData);
        } catch (smsError) {
          console.error("Failed to send service completion SMS:", smsError);
        }
      }

      if (source === "returns") {
        await axios.post(`/api/set-in-stock/${formData.serialNumber}`);
      } else if (source === "orders") {
        await axios.put(`/api/circuly-orders/${data.orderId}/tag`, {
          tag: "waiting for dispatch",
        });
      }

      if (typeof markAsRepaired === "function") await markAsRepaired(data._id);
      if (typeof reloadData === "function") await reloadData();
      toggleModalVisibility();
    } catch (error) {
      setErrorMessage(`Error: ${error.response?.data?.error || error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={toggleModalVisibility}
      contentLabel="Service Registration"
      style={modalStyles}
    >
      <ServiceRegistrationForm
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        toggleModalVisibility={toggleModalVisibility}
        errorMessage={errorMessage}
        isLoading={isLoading}
        doNotSaveService={doNotSaveService}
        setDoNotSaveService={setDoNotSaveService}
        groupedParts={groupedParts} // Pass grouped parts to the form
        selectedParts={selectedParts} // Pass selected parts
        setSelectedParts={setSelectedParts} // Function to update selected parts
        selectedAccessories={selectedAccessories}
        setSelectedAccessories={setSelectedAccessories}
        source={source}
      />
    </ReactModal>
  );
};

export default ServiceCell;
