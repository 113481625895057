import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import Table from "../components/tableComponent";
import { DateTime } from "luxon";

const OrderPickingList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterInput, setFilterInput] = useState("");
  const [filterCategory, setFilterCategory] = useState("");

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get("/api/filtered-orders");
        console.log("API Response:", response.data);

        const fetchedItems = response.data.orders.flatMap((order) =>
          order.items.map((item) => {
            try {
              // Parse raw tag date as-is, without adjusting for time zones
              const tagDate = DateTime.fromISO(order.tag_date, {
                setZone: true,
              });

              const hours = tagDate.hour;
              let deliveryTime;

              // Delivery time logic
              if (hours === 9) {
                deliveryTime = "09-17";
              } else if (hours === 17) {
                deliveryTime = "17-22";
              } else {
                deliveryTime = "N/A";
              }

              console.log(
                `Raw Tag Date: ${
                  order.tag_date
                }, Parsed Date: ${tagDate.toISO()}, Hours: ${hours}, Delivery Time: ${deliveryTime}`
              );

              return {
                orderId: item.order_id,
                productId: item.product_id,
                sku: item.sku,
                origin:
                  order.origin === "cms"
                    ? "swap"
                    : order.origin === "checkout"
                    ? "new order"
                    : order.origin,
                shippingMethod: order.shipping_method || "Unknown",
                tagDate: tagDate.toJSDate(), // Convert Luxon DateTime to JavaScript Date
                deliveryTime,
              };
            } catch (error) {
              console.error("Error processing item:", item, error);
              return null;
            }
          })
        );

        const validItems = fetchedItems.filter(Boolean);
        console.log("Processed Data:", validItems);
        setData(validItems);
      } catch (error) {
        console.error("Error fetching items:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, []);

  const columns = useMemo(
    () => [
      { Header: "Order ID", accessor: "orderId" },
      { Header: "Product ID", accessor: "productId" },
      { Header: "SKU", accessor: "sku" },
      { Header: "Origin", accessor: "origin" },
      { Header: "Shipping Method", accessor: "shippingMethod" },
      {
        Header: "Delivery Date",
        accessor: "tagDate", // Use raw date for sorting
        sortType: (rowA, rowB) => {
          const dateA = new Date(rowA.values.tagDate).getTime();
          const dateB = new Date(rowB.values.tagDate).getTime();
          return dateA - dateB; // Ascending order
        },
        Cell: ({ value }) => {
          if (!value) {
            console.error("Invalid tagDate value:", value);
            return "Invalid Date";
          }
          return DateTime.fromJSDate(value).toFormat("dd.MM.yyyy");
        },
      },
      {
        Header: "Delivery Time",
        accessor: "deliveryTime",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    setFilter,
    rows,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        sortBy: [{ id: "tagDate", desc: false }], // Sort by raw date
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const handleFilterChange = (e) => {
    setFilterInput(e.target.value);
    if (filterCategory) {
      setFilter(filterCategory, e.target.value);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="flex-grow p-4">
      <h2 className="text-2xl font-semibold mb-4">Order Picking List</h2>

      {/* Filter Inputs */}
      <div className="flex justify-between mb-4 items-center">
        <select
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
          className="border p-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-400"
        >
          <option value="">Select Filter Category...</option>
          {columns.map((column, idx) => (
            <option value={column.accessor} key={idx}>
              {column.Header}
            </option>
          ))}
        </select>
      </div>

      {/* Text input for entering filter values */}
      <input
        value={filterInput}
        onChange={handleFilterChange}
        placeholder="Enter filter value..."
        className="border p-2 mt-4 rounded w-full focus:outline-none focus:ring-2 focus:ring-indigo-400"
      />

      {/* Table Component */}
      <Table
        columns={columns}
        data={page}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalFilteredRows={rows.length}
      />
    </div>
  );
};

export default OrderPickingList;
