// Modal that opens when a user wants to mark a bike as returned (only cancellations)
// There should be checkboxes for items that should be returned
// If the user clicks "Mark as returned", the status of the bike should be updated to "Returned"
// There should be an external API call to Circuly to mark the bike as returned in that system
// If any of the boxes are not checked, an API post request should be made to our server side code
// This API request should open a new missing item report for the bike/customer in our system

import React, { useState } from "react";
import "../calendar.css";
import ReactModal from "react-modal";
import axios from "axios";
import { FaCheckSquare, FaRegSquare } from "react-icons/fa"; // Import icons from react-icons

ReactModal.setAppElement("#root");

const modalStyles = {
  content: {
    maxWidth: "700px", // Wider modal
    width: "90%", // Responsive width
    margin: "auto",
    padding: "30px",
    borderRadius: "12px",
    border: "4px solid #374151", // Darker, thicker border for stronger contrast
    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.6)", // Stronger shadow for even more depth
    overflow: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)", // Darker overlay for higher contrast with modal
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    zIndex: 1000,
  },
};

const ReturnConfirmationModal = ({
  isOpen,
  toggleModalVisibility,
  data,
  markAsReturned,
  markAsRepaired,
}) => {
  const [returnOption, setReturnOption] = useState("returnRepair"); // Default to return-repair,
  const [isMissing, setIsMissing] = useState(true);
  const [selectedItems, setSelectedItems] = useState({
    charger: false,
    adapter: false,
    lock: false,
    keysToLock: false,
    keysToBattery: false,
    retyreSkins: false,
    other: false,
  });
  const [note, setNote] = useState("");

  const handleCheckboxChange = (e) => {
    setSelectedItems({ ...selectedItems, [e.target.name]: e.target.checked });
  };

  const handleOptionChange = (option) => {
    setReturnOption(option);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const missingItems = Object.entries(selectedItems)
      .filter(([key, value]) => value)
      .map(([key]) => key);

    try {
      if (isMissing && missingItems.length > 0) {
        await axios.post("/api/missing-items", {
          serialNumber: data.serialNumber,
          fullName: `${data.firstName} ${data.lastName}`,
          phone: data.phone,
          email: data.email,
          itemSkuName: data.itemSkuName,
          missingItems: missingItems,
          note,
          missingItemStatus: "open",
        });
      }

      // Step 1: Trigger Circuly API to mark as returned
      const returnResponse = await axios.post(
        `/api/mark-as-returned/${data.serialNumber}`
      );
      if (returnResponse.status !== 200)
        throw new Error("Failed to mark as returned");

      // Step 2: Update internal return booking status including setting return method and date if not selected by customer.
      await markAsReturned(data._id);

      // Step 3: Handle "in-stock" or "return-repair" status based on user selection
      if (returnOption === "inStock") {
        const inStockResponse = await axios.post(
          `/api/set-in-stock/${data.serialNumber}`
        );
        if (inStockResponse.status !== 200)
          throw new Error("Failed to set as in stock");

        // Mark as repaired and update operational status
        await markAsRepaired(data._id);
        await axios.put(
          `/api/inventory/${data.serialNumber}/operationalStatus`,
          {
            operationalStatus: "in-stock",
          }
        );
      } else {
        await axios.put(
          `/api/inventory/${data.serialNumber}/operationalStatus`,
          {
            operationalStatus: "repair-returned",
          }
        );
      }

      toggleModalVisibility(); // Close the modal
    } catch (error) {
      console.error("Error processing return:", error);
    }
  };

  const isSubmitDisabled =
    isMissing && !Object.values(selectedItems).some(Boolean);

  const handleMissingSelect = (missing) => {
    setIsMissing(missing);
  };

  //Styled checkbox
  const Checkbox = ({ name, label }) => {
    return (
      <label className="flex items-center space-x-2 cursor-pointer">
        <input
          type="checkbox"
          name={name}
          checked={selectedItems[name]}
          onChange={handleCheckboxChange}
          className="hidden" // Hide default checkbox
        />
        {selectedItems[name] ? (
          <FaCheckSquare className="text-joule text-xl" />
        ) : (
          <FaRegSquare className="text-gray-400 text-xl" />
        )}
        <span className="text-gray-700">{label}</span>
      </label>
    );
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={toggleModalVisibility}
      contentLabel="Return Confirmation"
      className="modal-content"
      style={modalStyles}
    >
      <div className="text-center mb-6">
        <div className="mb-8">
          <h2 className="text-gray-900 text-2xl font-semibold text-center mb-6">
            Return Confirmation - Bike {data.serialNumber}
          </h2>
        </div>
        {/* Divider */}
        <hr className="my-6 border-t border-gray-300" />
        <form
          onSubmit={handleFormSubmit}
          className="bg-white rounded px-8 pt-6 pb-8"
        >
          {/* Header and buttons for setting in-stock or repair */}
          <div className="mb-6">
            <h3 className="text-gray-700 font-semibold text-lg mb-2">
              Set Bike Status
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <button
                type="button"
                onClick={() => handleOptionChange("inStock")}
                className={`w-full py-2 px-6 font-semibold rounded-lg shadow-md focus:outline-none ${
                  returnOption === "inStock"
                    ? "bg-joule text-white shadow-black shadow-md"
                    : "bg-gray-400 text-white hover:bg-joule hover:text-white"
                }`}
              >
                Set in Stock
              </button>
              <button
                type="button"
                onClick={() => handleOptionChange("returnRepair")}
                className={`w-full py-2 px-6 font-semibold rounded-lg shadow-md focus:outline-none ${
                  returnOption === "returnRepair"
                    ? "bg-joule text-white shadow-black shadow-md"
                    : "bg-gray-400 text-white hover:bg-joule hover:text-white"
                }`}
              >
                Put in Repair
              </button>
            </div>
          </div>

          {/* Header and buttons for missing items */}
          <div className="mb-6">
            <h3 className="text-gray-700 font-semibold text-lg mb-2">
              Missing Items
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <button
                type="button"
                onClick={() => handleMissingSelect(false)}
                className={`w-full py-2 px-6 font-semibold rounded-lg shadow-md focus:outline-none ${
                  !isMissing
                    ? "bg-joule text-white shadow-black shadow-md"
                    : "bg-gray-400 text-white hover:bg-joule hover:text-white"
                }`}
              >
                Nothing is Missing
              </button>
              <button
                type="button"
                onClick={() => handleMissingSelect(true)}
                className={`w-full py-2 px-6 font-semibold rounded-lg shadow-md focus:outline-none ${
                  isMissing
                    ? "bg-joule text-white shadow-black shadow-md"
                    : "bg-gray-400 text-white hover:bg-joule hover:text-white"
                }`}
              >
                Something is Missing
              </button>
            </div>
          </div>

          {/* Checkboxes for specific missing items */}
          {isMissing && (
            <div className="mb-6 grid grid-cols-1 sm:grid-cols-2 gap-4">
              <Checkbox name="charger" label="Charger" />
              <Checkbox name="adapter" label="Adapter" />
              <Checkbox name="lock" label="Lock" />
              <Checkbox name="keysToLock" label="2 sets of keys to lock" />
              <Checkbox
                name="keysToBattery"
                label="2 sets of keys to battery"
              />
              <Checkbox name="retyreSkins" label="Retyre Skins" />
              <Checkbox name="other" label="Other (specify in notes)" />
            </div>
          )}

          {/* Note section */}
          {isMissing && (
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-semibold mb-2"
                htmlFor="note"
              >
                Note
              </label>
              <textarea
                className="border-gray-600 shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-joule focus:ring-1 focus:ring-joule"
                id="note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Additional notes..."
              />
            </div>
          )}

          {/* Divider */}
          <hr className="my-6 border-t border-gray-300" />

          {/* Action buttons */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
            <button
              type="submit"
              className={`w-full py-2 px-6 font-semibold rounded-lg shadow-md focus:outline-none ${
                isSubmitDisabled
                  ? "bg-gray-500 text-white"
                  : "bg-joule text-white hover:bg-gray-900"
              }`}
              disabled={isSubmitDisabled}
            >
              Mark as Returned
            </button>
            <button
              type="button"
              className="w-full py-2 px-6 font-semibold rounded-lg shadow-md bg-red-500 text-white hover:bg-red-700 focus:outline-none"
              onClick={toggleModalVisibility}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </ReactModal>
  );
};

export default ReturnConfirmationModal;
