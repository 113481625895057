import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import axios from "axios";

ReactModal.setAppElement("#root");

const modalStyles = {
  content: {
    maxWidth: "700px",
    width: "90%",
    margin: "auto",
    padding: "30px",
    borderRadius: "12px",
    border: "4px solid #374151",
    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.6)",
    overflow: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    zIndex: 1000,
  },
};

const NewPartModal = ({
  isOpen,
  onRequestClose,
  onSubmit,
  suppliers: initialSuppliers,
  groupCodes: initialGroupCodes,
  componentCodes,
  compatibilityOptions,
}) => {
  const [formData, setFormData] = useState({
    productDescription: "",
    productNo: "",
    supplier: "",
    purchaseCostExVat: "",
    salesPriceIncVat: "",
    MRSPIncVat: "",
    groupCode: "",
    componentCode: "",
    partCode: "",
    modelCompatibility: [],
  });

  const [suppliers, setSuppliers] = useState(initialSuppliers);
  const [groupCodes, setGroupCodes] = useState(initialGroupCodes);
  const [filteredComponents, setFilteredComponents] = useState([]);

  const [newSupplier, setNewSupplier] = useState("");
  const [newGroupCode, setNewGroupCode] = useState("");
  const [newComponentCode, setNewComponentCode] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Update component codes based on selected group code
    if (formData.groupCode && componentCodes[formData.groupCode]) {
      setFilteredComponents(componentCodes[formData.groupCode]);
    } else {
      setFilteredComponents([]);
    }
  }, [formData.groupCode, componentCodes]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "groupCode" && { componentCode: "", partCode: "" }),
      ...(name === "componentCode" && { partCode: `${value.substring(0, 5)}` }),
    }));
  };

  const handleCheckboxChange = (option) => {
    setFormData((prev) => ({
      ...prev,
      modelCompatibility: prev.modelCompatibility.includes(option)
        ? prev.modelCompatibility.filter((model) => model !== option)
        : [...prev.modelCompatibility, option],
    }));
  };

  const handleAddNewSupplier = () => {
    if (newSupplier && !suppliers.includes(newSupplier)) {
      setSuppliers((prev) => [...prev, newSupplier]);
      setFormData((prev) => ({ ...prev, supplier: newSupplier }));
      setNewSupplier("");
    }
  };

  const handleAddNewGroupCode = () => {
    if (newGroupCode && !groupCodes.includes(newGroupCode)) {
      setGroupCodes((prev) => [...prev, newGroupCode]);
      setFormData((prev) => ({ ...prev, groupCode: newGroupCode }));
      setNewGroupCode("");
    }
  };

  const handleAddNewComponentCode = () => {
    if (newComponentCode && !filteredComponents.includes(newComponentCode)) {
      setFilteredComponents((prev) => [...prev, newComponentCode]);
      setFormData((prev) => ({ ...prev, componentCode: newComponentCode }));
      setNewComponentCode("");
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("/api/service-parts", formData);
      if (response.status === 201) {
        onSubmit(response.data);
        onRequestClose();
      }
    } catch (error) {
      console.error("Error adding new part:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add New Part"
      style={modalStyles}
    >
      <h2 className="text-gray-900 text-3xl font-semibold">Add new part</h2>
      <hr className="border-t border-gray-300 my-6" />
      <form onSubmit={handleFormSubmit} className="space-y-6">
        {/* Product Description */}
        <div>
          <label className="block font-semibold text-gray-700 mb-1">
            Product Description
          </label>
          <input
            name="productDescription"
            placeholder="Product Description"
            value={formData.productDescription}
            onChange={handleChange}
            className="border w-full py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-joule"
          />
        </div>

        {/* Product Number */}
        <div>
          <label className="block font-semibold text-gray-700 mb-1">
            Product Number
          </label>
          <input
            name="productNo"
            placeholder="Product Number"
            value={formData.productNo}
            onChange={handleChange}
            className="border w-full py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-joule"
          />
        </div>

        {/* Supplier Selection with Add New */}
        <div>
          <label className="block font-semibold text-gray-700 mb-1">
            Supplier
          </label>
          <select
            name="supplier"
            value={formData.supplier}
            onChange={(e) =>
              setFormData({ ...formData, supplier: e.target.value })
            }
            className="border w-full py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-joule"
          >
            <option value="">Select Supplier</option>
            {suppliers.map((sup) => (
              <option key={sup} value={sup}>
                {sup}
              </option>
            ))}
            <option value="add-new">+ Add New Supplier</option>
          </select>
          {formData.supplier === "add-new" && (
            <div className="flex mt-2 space-x-2">
              <input
                type="text"
                placeholder="Enter New Supplier"
                value={newSupplier}
                onChange={(e) => setNewSupplier(e.target.value)}
                className="border w-full py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-joule"
              />
              <button
                type="button"
                onClick={handleAddNewSupplier}
                className="bg-joule text-white px-4 rounded"
              >
                Add
              </button>
            </div>
          )}
        </div>

        {/* Purchase and Sales Prices */}
        <div>
          <label className="block font-semibold text-gray-700 mb-1">
            Purchase Cost (Ex VAT)
          </label>
          <input
            type="number"
            step="0.01"
            name="purchaseCostExVat"
            placeholder="Purchase Cost (Ex VAT)"
            value={formData.purchaseCostExVat}
            onChange={handleChange}
            className="border w-full py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-joule"
          />
        </div>
        <div>
          <label className="block font-semibold text-gray-700 mb-1">
            Sales Price (Inc VAT)
          </label>
          <input
            type="number"
            step="0.01"
            name="salesPriceIncVat"
            placeholder="Sales Price (Inc VAT)"
            value={formData.salesPriceIncVat}
            onChange={handleChange}
            className="border w-full py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-joule"
          />
        </div>
        <div>
          <label className="block font-semibold text-gray-700 mb-1">
            MRSP (Inc VAT)
          </label>
          <input
            type="number"
            step="0.01"
            name="MRSPIncVat"
            placeholder="MRSP (Inc VAT)"
            value={formData.MRSPIncVat}
            onChange={handleChange}
            className="border w-full py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-joule"
          />
        </div>

        {/* Group Code Selection with Add New */}
        <div>
          <label className="block font-semibold text-gray-700 mb-1">
            Group Code
          </label>
          <select
            name="groupCode"
            value={formData.groupCode}
            onChange={handleChange}
            className="border w-full py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-joule"
          >
            <option value="">Select Group Code</option>
            {groupCodes.map((group) => (
              <option key={group} value={group}>
                {group}
              </option>
            ))}
            <option value="add-new">Add New Group Code</option>
          </select>
          {formData.groupCode === "add-new" && (
            <div className="flex mt-2 space-x-2">
              <input
                type="text"
                placeholder="Enter New Group Code"
                value={newGroupCode}
                onChange={(e) => setNewGroupCode(e.target.value)}
                className="border w-full py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-joule"
              />
              <button
                type="button"
                onClick={handleAddNewGroupCode}
                className="bg-joule text-white px-4 rounded"
              >
                Add
              </button>
            </div>
          )}
        </div>

        {/* Component Code Selection with Add New */}
        <div>
          <label className="block font-semibold text-gray-700 mb-1">
            Component Code
          </label>
          <select
            name="componentCode"
            value={formData.componentCode}
            onChange={handleChange}
            className="border w-full py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-joule"
            disabled={!formData.groupCode}
          >
            <option value="">Select Component Code</option>
            {filteredComponents.map((comp) => (
              <option key={comp} value={comp}>
                {comp}
              </option>
            ))}
            <option value="add-new">Add New Component Code</option>
          </select>
          {formData.componentCode === "add-new" && (
            <div className="flex mt-2 space-x-2">
              <input
                type="text"
                placeholder="Enter New Component Code"
                value={newComponentCode}
                onChange={(e) => setNewComponentCode(e.target.value)}
                className="border w-full py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-joule"
              />
              <button
                type="button"
                onClick={handleAddNewComponentCode}
                className="bg-joule text-white px-4 rounded"
              >
                Add
              </button>
            </div>
          )}
        </div>

        {/* Part Code */}
        <div>
          <label className="block font-semibold text-gray-700 mb-1">
            Part Code
          </label>
          <input
            name="partCode"
            placeholder="Part Code"
            value={formData.partCode}
            onChange={handleChange}
            className="border w-full py-2 px-3 rounded focus:outline-none focus:ring-2 focus:ring-joule"
          />
        </div>

        {/* Model Compatibility */}
        <div>
          <label className="block font-semibold text-gray-700 mb-2">
            Compatible Bikes
          </label>
          <div className="grid grid-cols-2 gap-2">
            {compatibilityOptions.map((option) => (
              <label
                key={option}
                className="flex items-center space-x-3 text-gray-700"
              >
                <input
                  type="checkbox"
                  checked={formData.modelCompatibility.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                  className="form-checkbox rounded-full h-5 w-5 accent-joule"
                />
                <span>{option}</span>
              </label>
            ))}
          </div>
        </div>

        <hr className="border-t border-gray-300" />
        {/* Action Buttons */}
        <div className="flex justify-between mt-6">
          <button
            type="submit"
            disabled={loading}
            className={`py-2 px-4 rounded-lg w-full mr-2 ${
              loading
                ? "bg-gray-400 cursor-wait text-white"
                : "bg-joule text-white hover:bg-joule-dark"
            }`}
          >
            {loading ? "Adding Part…" : "Add Part"}
          </button>
          <button
            type="button"
            onClick={onRequestClose}
            disabled={loading}
            className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-700 w-full ml-2"
          >
            Cancel
          </button>
        </div>
      </form>
    </ReactModal>
  );
};

export default NewPartModal;
